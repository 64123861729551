<template>
<div class="h-full w-full">
  <div class="flex flex-col mx-5 my-5 md:hidden">
    <div class="flex flex-row gap-2.5 items-center">
      <div class="h-30 bg-baseOrange w-1 rounded"></div>
      <span class=" text-2xl font-bold">Харилцагч</span>
    </div>
    <div class="flex flex-row justify-center items-center mt-5 px-2.5 py-2.5 w-full rounded-lg bg-baseGray text-xs font-medium">Нийт {{$root.chosenOutlets.length}} салбар байна.</div>
  </div>
  <ul class="border-r border-r-[1px] border-solid md:w-216 w-full md:h-full font-normal text-11 border-baseGray" style="min-width: 216px">
    <li class="flex flex-row items-center border-b border-solid border-baseGray h-43 px-15 cursor-pointer"
      v-for="(outlet, indexOutlet) in $root.chosenOutlets"
      :key="indexOutlet"
      :class="
        currentOutlet && outlet._id === currentOutlet?._id
          ? 'text-baseBlack font-semibold Acitve'
          : ''
      "
      @click="setCurrentOutlet(outlet)">
      <span>{{ indexOutlet + 1 }}. {{ outlet.full_name_eng }}</span>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'OutletListComponent',
  props: {
    setCurrentOutlet: {
      type: Function,
      default: () => null
    },
    currentOutlet: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style>
.Acitve {
  box-shadow: inset -11px 0 0px -7px #ffb500;
}
</style>
