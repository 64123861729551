<template>
  <div
    class="flex md:flex-row flex-col w-full h-full md:right-2 right-0 md:pb-0 pb-14"
  >
    <!-- Outlet section -->
    <div class="md:block hidden">
      <outlet-list-component
        :setCurrentOutlet="setCurrentOutlet"
        :currentOutlet="currentOutlet"
      />
    </div>
    <!-- Category section -->
    <div class="md:block hidden">
      <category-products-component
        v-if="outletData"
        :partnerCategories="partnerCategories"
        :products="products"
        :setSelectCategory="setSelectCategory"
        :getMenu="getMenu"
        :activeProduct="activeProduct"
        :menuLoading="menuLoading"
        :selectCategory="selectCategory"
        :menu="menu"
        :addMenu="addMenu"
        :filterProduct="filterProduct"
        :addMenuCatgory="addMenuCatgory"
        :outletData="outletData"
        :getPartnerMenuCategories="getPartnerMenuCategories"
      />
    </div>
    <!-- Product Info -->
    <div
      class="w-full h-full md:pl-30 pl-5 md:pr-20 pr-5 md:pb-12 pb-5 text-baseBlack"
    >
      <div
        class="w-full my-2.5 text-11 md:flex md:flex-row md:justify-between hidden"
      >
        <div class="flex flex-row">
          <div
            class="px-15 py-2 bg-baseBackgroundGray rounded-l-lg flex flex-row justify-center items-center gap-5"
          >
            Хаалттай: <strong>{{ total_inactive_products }}</strong>
          </div>
          <div
            class="px-15 py-2 bg-baseOrange rounded-r-lg text-baseWhite flex flex-row justify-center items-center gap-5"
          >
            Нээлттэй: <strong>{{ total_active_products }}</strong>
          </div>
        </div>
        <div
          class="flex flex-row gap-5 bg-baseBackgroundGray px-17 py-15 text-xs rounded-xl justify-center items-center cursor-pointer"
          v-if="menu"
          @click="getProductHistoriesId(menu.product_id)"
        >
          <i class="el-icon-document"></i><strong>ТҮҮХ ХАРАХ</strong>
        </div>
      </div>
      <div class="w-full" v-if="menu">
        <div class="flex flex-row justify-between gap-2">
          <div class="flex md:flex-row flex-col gap-2.5 w-full">
            <div class="flex flex-row gap-2.5 items-center">
              <div
                class="h-30 bg-baseOrange w-1 rounded"
                style="max-height: 100%"
              ></div>
              <span class="text-24 font-bold">{{ menu?.name_mon }}</span>
            </div>
            <div
              class="bg-baseBackgroundGray text-12 md:h-full h-53 rounded-lg px-2.5 flex flex-row items-center gap-2.5"
              :class="
                menu.is_active
                  ? 'font-semibold'
                  : 'font-normal text-baseTextGray'
              "
            >
              <el-switch v-model="menu.is_active"></el-switch>
              <span>Бүтээгдэхүүн нээлттэй эсэх</span>
            </div>
          </div>
          <div class="md:flex md:flex-row h-full hidden text-12 gap-5">
            <button
              class="px-15 py-17 bg-baseBackgroundGray rounded-xl w-81 font-semibold"
              @click="menu = null"
            >
              Болих
            </button>
            <el-popconfirm
              title="Та устгахдаа итгэлтэй байна уу?"
              confirm-button-text="Тийм"
              cancel-button-text="Үгүй"
              @confirm="deleteProduct(menu?.product_id)"
              class="h-full"
            >
              <button
                slot="reference"
                class="custom-button px-15 py-17 bg-baseBackgroundGray text-baseRed rounded-xl w-81 font-semibold border-none"
              >
                Устгах
              </button>
            </el-popconfirm>
            <button
              class="px-15 py-17 bg-baseOrange rounded-xl w-81 font-semibold"
              @click="save(menu)"
            >
              Хадгалах
            </button>
          </div>
        </div>
        <el-form
          class="flex flex-col md:mt-8 mt-5"
          :model="menu"
          :rules="ruleProductForm"
          ref="refproductForm"
        >
          <div class="flex flex-row w-full gap-20">
            <div
              class="flex md:flex-row flex-col gap-20"
              style="min-width: 100%; max-width: 343px"
            >
              <!-- Image section -->
              <div
                class="flex flex-col w-full h-173 bg-baseImageBackground rounded-xl justify-center items-center"
                style="max-width: 343px"
              >
                <img
                  v-if="menu.img_url"
                  :src="menu.img_url"
                  class="w-full h-full rounded-xl"
                />
                <div
                  v-else
                  class="relative w-full h-full flex flex-col justify-center items-center"
                >
                  <span class="text-15 font-bold opacity-40">Таны баннер</span>
                  <span
                    class="text-baseImageGray absolute bottom-5 flex flex-row"
                    style="font-size: 10px"
                    >Шаардлага: Зургийн хэмжээ: 600х400px, Зургийн <br />
                    дээд хэмжээ: 2МВ. Зургийн формат: JPEG or PNG</span
                  >
                </div>
              </div>
              <div
                class="flex flex-col w-full md:h-173 md:bg-baseBackgroundGray rounded-xl relative justify-center items-center"
                style="max-width: 343px"
              >
                <el-upload
                  v-loading="loadUpload"
                  action=""
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="(file) => getFile(file)"
                >
                  <div
                    class="bg-baseGray py-17 px-15 rounded-xl text-12 flex flex-row gap-2"
                  >
                    <img
                      src="../../../assets/product/productImage.svg"
                      alt=""
                    />
                    <span>{{
                      menu?.img_url ? "Зураг солих" : "Зураг хуулах"
                    }}</span>
                  </div>
                </el-upload>
              </div>
              <div
                class="flex flex-col justify-between w-full"
                v-if="$root.isAdmin"
              >
                <div
                  class="w-full flex flex-col gap-5"
                  style="max-width: 343px"
                >
                  <span slot="label" class="font-semibold text-10"
                    >Онцлох бүтээгдэхүүн болгох эсэх?</span
                  >
                  <div
                    class="px-17 py-15 text-14 rounded-xl text-baseWhite bg-baseBackgroundGray border-2 border-baseGray flex flex-row justify-around"
                  >
                    <span
                      class="text-14 font-semibold"
                      :class="
                        !menu?.is_special
                          ? 'text-baseBlack'
                          : 'text-baseTextGray'
                      "
                      >Үгүй</span
                    >
                    <el-switch
                      v-model="menu.is_special"
                      @change="clearData"
                    ></el-switch>
                    <span
                      class="text-14 font-semibold"
                      :class="
                        menu?.is_special
                          ? 'text-baseBlack'
                          : 'text-baseTextGray'
                      "
                      >Тийм</span
                    >
                  </div>
                </div>
                <div class="flex flex-col gap-5" v-show="menu.is_special">
                  <span slot="label" class="font-semibold text-10"
                    >Онцлох ангилал сонгох</span
                  >
                  <el-select
                    class="custom-element-select text-14 cursor-pointer"
                    multiple
                    v-model="menu.special_text"
                    @change="setSpecialProduct"
                  >
                    <el-option
                      v-for="(menuItem, menuIndex) in suggestMenu"
                      :key="menuIndex"
                      :label="menuItem.name_mon"
                      :value="menuItem.name_mon"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="flex md:flex-row flex-col w-full gap-20 mt-5">
            <div class="flex flex-col w-full gap-20" style="max-width: 343px">
              <div class="flex flex-col gap-5">
                <span slot="label" class="font-semibold text-10"
                  >Бүтээгдэхүүний нэр</span
                >
                <input
                  v-model="menu.name_mon"
                  type="text"
                  class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                  placeholder="Нэр оруулах ..."
                />
              </div>
              <div class="flex flex-col gap-5">
                <span slot="label" class="font-semibold text-10"
                  >Бүтээгдэхүүний нэр ( Англи )</span
                >
                <input
                  v-model="menu.name_eng"
                  type="text"
                  class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                  placeholder="Англи нэр оруулах ..."
                />
              </div>
            </div>
            <div class="flex flex-col w-full gap-20" style="max-width: 343px">
              <div class="flex flex-col h-full gap-5">
                <span slot="label" class="font-semibold text-10">Тайлбар</span>
                <textarea
                  v-model="menu.desc_mon"
                  class="w-full h-full border-2 rounded-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                  placeholder="Тайлбар оруулах ..."
                />
              </div>
            </div>
            <div class="flex flex-col w-full gap-20" style="max-width: 343px">
              <div class="flex flex-col gap-5">
                <span slot="label" class="font-semibold text-10"
                  >Бэлдэх хугацаа</span
                >
                <div class="flex flex-row">
                  <input
                    v-model="menu.menu_avg_time"
                    type="text"
                    @change="changeAvgTime(menu.menu_avg_time, outletData)"
                    class="w-full border-2 rounded-l-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                    placeholder="Хугацаа оруулах ..."
                  />
                  <span
                    class="flex flex-row justify-center items-center bg-baseGray w-16 rounded-r-xl uppercase"
                    >Минут</span
                  >
                </div>
              </div>
              <div class="flex flex-col gap-5">
                <span slot="label" class="font-semibold text-10"
                  >Бүтээгдэхүүний эрэмбэ</span
                >
                <input
                  v-model="menu.menu_sort"
                  type="text"
                  class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                  placeholder="Эрэмбэ оруулах ..."
                />
              </div>
            </div>
          </div>
          <div class="flex md:flex-row flex-col w-full gap-20 mt-5">
            <div class="flex flex-col w-full gap-20" style="max-width: 343px">
              <div class="flex flex-col gap-5">
                <span slot="label" class="font-semibold text-10"
                  >Дотоод ангилал</span
                >
                <el-select
                  class="custom-element-select text-14 cursor-pointer"
                  value-key="menu_category_mon"
                  v-model="menu.menu_categories"
                >
                  <el-option
                    v-for="(
                      mMenuCategory, mMenuCategoryIndex
                    ) in outletData?.menu_categories"
                    :key="mMenuCategoryIndex"
                    :label="mMenuCategory.menu_category_mon"
                    :value="mMenuCategory"
                  >
                    {{ mMenuCategory.menu_category_mon }}
                  </el-option>
                </el-select>
              </div>
              <div class="flex flex-col gap-5">
                <span slot="label" class="font-semibold text-10">Үнэ</span>
                <div class="flex flex-row">
                  <input
                    v-model="menu.menu_price"
                    type="text"
                    class="w-full border-2 rounded-l-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                    placeholder="Үнэ оруулах ..."
                  />
                  <span
                    class="flex flex-row justify-center items-center bg-baseGray rounded-r-xl w-52"
                    >₮</span
                  >
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full gap-20" style="max-width: 343px">
              <div class="flex flex-col h-full gap-5">
                <span slot="label" class="font-semibold text-10"
                  >Тайлбар ( Англи )</span
                >
                <textarea
                  v-model="menu.desc_eng"
                  class="w-full h-full border-2 rounded-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                  placeholder="Тайлбар оруулах ..."
                />
              </div>
            </div>
          </div>
          <div class="w-full border-1 border-dashed border-baseGray my-5"></div>
          <div class="flex md:flex-row flex-col w-full gap-20">
            <div class="w-full flex flex-col gap-5" style="max-width: 343px">
              <span slot="label" class="font-semibold text-10"
                >Бүтээгдэхүүний шинж чанар</span
              >
              <div
                class="flex flex-row bg-baseBlue px-17 py-15 text-10 rounded-xl text-baseWhite justify-center items-center cursor-pointer"
                @click="addProperties"
              >
                Нэмэх
              </div>
            </div>
            <div
              class="flex flex-col w-full gap-2.5"
              v-if="propertiesData.length > 0"
            >
              <div
                class="flex flex-row w-full justify-between gap-2.5"
                v-for="(properties, indexProperties) in propertiesData"
                :key="indexProperties"
              >
                <div class="flex flex-col gap-5 w-full">
                  <span slot="label" class="font-semibold text-10">Төрөл</span>
                  <el-select
                    class="custom-element-select text-14 cursor-pointer"
                    v-model="properties.name"
                  >
                    <el-option
                      v-for="(nameItem, indexName) in productProperties"
                      :key="indexName"
                      :label="nameItem.name"
                      :value="nameItem.name"
                    >
                      {{ nameItem.name }}
                    </el-option>
                  </el-select>
                </div>
                <div class="flex flex-col gap-5 w-full">
                  <span slot="label" class="font-semibold text-10">Нэр</span>
                  <el-select
                    class="custom-element-select text-14 cursor-pointer"
                    v-model="properties.value"
                  >
                    <el-option
                      v-for="(itemValue, indexValue) in productProperties"
                      :key="indexValue"
                      :label="itemValue.value"
                      :value="itemValue.value"
                    >
                      {{ itemValue.value }}
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="flex flex-row items-center px-15 py-17 bg-baseBackgroundGray text-baseRed rounded-xl font-semibold text-xs mt-5 cursor-pointer"
                  @click="deleteProperties(indexProperties)"
                >
                  Устгах
                </div>
              </div>
            </div>
            <div v-else class="w-full">
              <span
                class="flex flex-row w-full h-full items-center justify-center opacity-50"
                >Өгөгдөл байхгүй байна...</span
              >
            </div>
          </div>
          <div class="w-full border-1 border-dashed border-baseGray my-5"></div>
          <div class="flex md:flex-row flex-col gap-20">
            <div class="w-full flex flex-col gap-5" style="max-width: 343px">
              <span slot="label" class="font-semibold text-10"
                >Бүтээгдэхүүнд дагалдуулж өгөх үү?</span
              >
              <div
                class="px-17 py-15 text-14 rounded-xl text-baseWhite bg-baseBackgroundGray border-2 border-baseGray flex flex-row justify-around"
              >
                <span
                  class="text-14 font-semibold"
                  :class="
                    !menu.is_addon ? 'text-baseBlack' : 'text-baseTextGray'
                  "
                  >Үгүй</span
                >
                <el-switch
                  v-model="menu.is_addon"
                  @change="onChangeIsAddon"
                ></el-switch>
                <span
                  class="text-14 font-semibold"
                  :class="
                    menu.is_addon ? 'text-baseBlack' : 'text-baseTextGray'
                  "
                  >Тийм</span
                >
              </div>
            </div>
            <div class="flex flex-col w-full" v-if="menu.is_addon">
              <div class="flex flex-row w-full gap-2.5">
                <div class="flex flex-col gap-5 w-full">
                  <span slot="label" class="font-semibold text-10"
                    >Дагалдуулах хоолоо сонгоно уу</span
                  >
                  <el-select
                    class="custom-element-select"
                    multiple
                    value-key="name_mon"
                    v-model="menu.addon_menus"
                    @change="onChangeAddonMenuSelect"
                  >
                    <el-option
                      v-for="(tempMenu, tempMenuIndex) in outletMenus"
                      :key="tempMenuIndex"
                      :label="tempMenu.name_mon"
                      :value="tempMenu"
                    >
                      {{ tempMenu.name_mon }}
                    </el-option>
                  </el-select>
                </div>
                <div
                  class="flex flex-row items-center justify-center bg-baseBackgroundGray rounded-xl w-87 px-2.5 gap-5 mt-5 cursor-pointer"
                  style="min-width: 87px"
                  @click="setAllProduct(isAllFood)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M12 22.4656C17.5 22.4656 22 17.9656 22 12.4656C22 6.96558 17.5 2.46558 12 2.46558C6.5 2.46558 2 6.96558 2 12.4656C2 17.9656 6.5 22.4656 12 22.4656Z"
                      :fill="isAllFood ? '#FFA500' : '#A1A1A1'"
                    />
                    <circle
                      cx="12.0006"
                      cy="12.4658"
                      r="4.28571"
                      fill="white"
                    />
                  </svg>
                  <span
                    class="text-10"
                    :class="isAllFood ? '' : 'text-baseBoxGray'"
                    >Бүх хоол</span
                  >
                </div>
              </div>
              <div
                class="flex flex-col w-full mt-5"
                v-if="menu.addon_menus.length > 0"
              >
                <div
                  class="flex md:flex-row flex-col gap-2.5"
                  v-for="(addOn, addOnIndex) in menu.addon_menus"
                  :key="addOnIndex"
                >
                  <div class="flex flex-col gap-5 w-full">
                    <span slot="label" class="font-semibold text-10">Багц</span>
                    <input
                      type="text"
                      v-model="addOn.name_mon"
                      disabled
                      class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                      placeholder="Нэр оруулах ..."
                    />
                  </div>
                  <div class="flex flex-row gap-2.5 w-full">
                    <div class="flex flex-col gap-5 w-full">
                      <span slot="label" class="font-semibold text-10"
                        >Үнэ</span
                      >
                      <div class="flex flex-row">
                        <input
                          type="text"
                          v-model="addOn.addon_price"
                          class="w-full border-2 rounded-l-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                          placeholder="Үнэ оруулах ..."
                        />
                        <span
                          class="flex flex-row justify-center items-center bg-baseGray w-14 rounded-r-xl"
                          >₮</span
                        >
                      </div>
                    </div>
                    <div class="flex flex-row mt-5">
                      <button
                        class="bg-baseBackgroundGray flex flex-row items-center justify-center w-52 rounded-l-xl border-2 border-solid border-baseGray"
                      >
                        -
                      </button>
                      <input
                        type="text"
                        v-model="addOn.addon_limit"
                        class="w-52 pl-5 border-t-2 border-b-2 border-baseGray"
                        style="min-width: 52px"
                      />
                      <button
                        class="bg-baseBackgroundGray flex flex-row items-center justify-center w-52 rounded-r-xl border-2 border-solid border-baseGray"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="w-full mt-4">
                <span
                  class="flex flex-row w-full h-full items-center justify-center opacity-50"
                  >Өгөгдөл байхгүй байна...</span
                >
              </div>
            </div>
          </div>
          <div class="w-full border-1 border-dashed border-baseGray my-5"></div>
          <div
            class="flex flex-row items-center justify-center bg-baseBackgroundGray rounded-xl w-full py-15 gap-5 cursor-pointer"
            @click="openVariantForm(null)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M11.5 13.9658H5.5V11.9658H11.5V5.96582H13.5V11.9658H19.5V13.9658H13.5V19.9658H11.5V13.9658Z"
                fill="#3A3A3C"
              />
            </svg>
            <span class="text-14 font-semibold"
              >Бүтээгдэхүүнд сонголт оруулах</span
            >
          </div>
          <!-- Variant -->
          <div
            class="flex flex-col mt-4"
            v-for="(variant, indexVariant) in menu.variants"
            :key="indexVariant"
          >
            <div class="flex flex-row justify-start items-center gap-2.5">
              <span class="font-semibold"
                >{{ variant.variant_name_mon }} -
                {{ variant.variant_name_eng }}</span
              >
              <el-tooltip
                v-if="variant.is_required"
                class="item"
                effect="dark"
                content="Заавал сонгох сонголт"
                placement="top"
              >
                <i
                  class="el-icon-success ml5 text-success"
                  style="font-size: 16px"
                ></i>
              </el-tooltip>
              <div
                class="flex flex-row justify-center text-center bg-baseBackgroundGray px-4 py-2 rounded-xl cursor-pointer"
                @click="editVariantFrom(variant)"
              >
                <i class="el-icon-edit text-base"></i>
              </div>
              <div
                class="flex flex-row justify-center text-center bg-baseBackgroundGray px-4 py-2 rounded-xl cursor-pointer"
                @click="deleteItem(variant)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                    stroke="#F24E1E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div class="w-full" v-if="variant.options.length > 0">
              <div
                class="flex md:flex-row flex-col gap-2.5 w-full mt-4"
                v-for="(option, optionIndex) in variant.options"
                :key="optionIndex"
              >
                <div class="flex flex-col gap-5 w-full">
                  <span class="font-semibold text-10" v-if="optionIndex === 0"
                    >Сонголтын нэр</span
                  >
                  <input
                    v-model="option.option_name_mon"
                    type="text"
                    class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                    placeholder="Хар ..."
                  />
                </div>
                <div class="flex flex-col gap-5 w-full">
                  <span class="font-semibold text-10" v-if="optionIndex === 0"
                    >Сонголтын нэр ( Англи )</span
                  >
                  <input
                    v-model="option.option_name_eng"
                    type="text"
                    class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                    placeholder="Black ..."
                  />
                </div>
                <div class="flex flex-col gap-5 w-full">
                  <span class="font-semibold text-10" v-if="optionIndex === 0"
                    >Үндсэн үнэ дээр нэмэх үнэ</span
                  >
                  <div class="flex flex-row gap-2.5">
                    <div class="flex flex-row w-full">
                      <input
                        type="text"
                        v-model="option.option_price"
                        class="w-full border-2 rounded-l-xl border-baseGray py-15 px-17 text-14 bg-baseBackgroundGray"
                        placeholder="Үнэ оруулах ..."
                      />
                      <span
                        class="flex flex-row justify-center items-center bg-baseGray w-52 rounded-r-xl"
                        >₮</span
                      >
                    </div>
                    <div
                      class="flex flex-row justify-center items-center px-15 md:h-full rounded-xl bg-baseBackgroundGray cursor-pointer"
                      @click="removeOption(variant, option)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                          stroke="#F24E1E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="w-full mt-4">
              <span
                class="flex flex-row w-full h-full items-center justify-center opacity-50"
                >Өгөгдөл байхгүй байна...</span
              >
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!-- MobileFooter -->
    <div
      class="flex flex-row md:hidden fixed bottom-0 border-t-1 border-solid border-baseGray w-full"
    >
      <MobileFooter :menu="menu" :save="save" :deleteProduct="deleteProduct" />
    </div>
    <!-- Add variant section -->
    <AddVariantDialog
      :dialogVisible="variantDialogVisible"
      :closeVariantDialog="closeVariantDialog"
      :addVariant="addVariant"
      :variantForm="currentVariant"
    ></AddVariantDialog>
    <history-dialog
      :isShow="isShowHistoryListDialog"
      :outletId="product_id ? product_id : null"
      :closeFunction="closeHistoryListDialog"
    ></history-dialog>
    <AddCategoryDialog
      :dialogVisible="categoryDialogVisible"
      :closeCategoryDialog="closeCategoryDialog"
      :addCategory="addCategory"
      :menuCategoryList="menuCategoryList"
    ></AddCategoryDialog>
  </div>
</template>
<script>
// import PlusIcon from 'vue-material-design-icons/Plus'
// import TitleItem from '../../../components/items/titleItem.vue'
import AddVariantDialog from "./components/AddVariantDialog";
import AddCategoryDialog from "./components/AddCategoryDialog";
// import ShowEditRequestDialog from './components/ShowEditRequestDialog'
// import ElCurrencyInput from './ElCurrencyInput.vue'
import { getBase64 } from "../../../helper/custom";
import mAxios from "../../../helper/axiosInstance";
import HistoryDialog from "./components/history/index.vue";
import OutletListComponent from "./components/OutletListComponent.vue";
import CategoryProductsComponent from "./components/CategoryProductsComponent.vue";
import MobileFooter from "./components/mobile/MobileFooter.vue";
export default {
  name: "Products",
  components: {
    // PlusIcon,
    AddVariantDialog,
    OutletListComponent,
    CategoryProductsComponent,
    MobileFooter,
    HistoryDialog,
    AddCategoryDialog,
    // ShowEditRequestDialog,
    // ElCurrencyInput,
    // TitleItem
  },
  created() {
    this.getMenuTimeLimits();
    this.getSuggestMenu();
    window.scrollTo(0, 0);
    if (this.$route.query?.productId && this.$route.query?.outletId) {
      this.getMenu(this.$route.query.productId);
      this.getOutletData(this.$route.query.outletId);
    } else if (this.$route.params.item && this.$route.query.outletId) {
      this.getOutletData(this.$route.query.outletId);
      setTimeout(() => {
        this.addMenu(this.$route.params.item);
      }, 500);
    }
  },
  mounted() {
    if (this.$root.chosenOutlets.length > 0) {
      this.setCurrentOutlet(this.$root.chosenOutlets[0]);
    }
    // this.getProductStat()
  },
  watch: {
    currentOutlet(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        // this.getMenus(newVal._id)
      } else {
        this.outletData = null;
        this.products = [];
      }
    },
  },
  data() {
    return {
      isAllFood: false,
      searchText: "",
      specialData: [],
      selected: [],
      suggestMenu: [],
      isSelectedProduct: false,
      copyLoading: false,
      copyProductDialog: false,
      isChecked: false,
      selectOutletValue: this.$root.chosenOutlets[0].full_name_eng,
      selectProductValue: "",
      selectSuppliers: {
        suppliers: [],
      },
      rules: {
        suppliers: [
          {
            type: "array",
            required: true,
            message: "Та хуулах салбараа сонгоно уу?",
            trigger: "change",
          },
        ],
      },
      selectedProducts: [],
      currentVariant: {
        variant_name_mon: "",
        variant_name_eng: "",
        is_required: false,
        options: [],
        action: "create",
      },
      menuLoading: false,
      loading: false,
      variantDialogVisible: false,
      categoryDialogVisible: false,
      editRequestDialogVisible: false,
      outlets: [],
      currentOutlet: null,
      outletData: null,
      total: null,
      products: [],
      backupData: [],
      productForm: null,
      isDialogShow: false,
      isDetailShow: false,
      ProductHistoryDetailID: [],
      productDetail: [],
      productdetailLogs: [],
      loadUpload: false,
      imageNull: "none",
      editRequest: null,
      menu: null,
      menuID: null,
      currentOutlet_item: [],
      outletMenus: [],
      isShowHistoryListDialog: false,
      product_id: null,
      ruleProductForm: {
        menu_price: [
          { required: true, message: "Үнийг оруулна уу", trigger: "blur" },
        ],
        name_mon: [
          {
            required: true,
            message: "Бүтээгдэхүүний монгол нэрийг оруулна уу",
            trigger: "blur",
          },
        ],
        name_eng: [
          {
            required: true,
            message: "Бүтээгдэхүүний англи нэрийг оруулна уу",
            trigger: "blur",
          },
        ],
        desc_mon: [
          {
            required: true,
            message: "Бүтээгдэхүүний монгол тайлбарыг оруулна уу",
            trigger: "blur",
          },
        ],
        desc_eng: [
          {
            required: true,
            message: "Бүтээгдэхүүний англи тайлбарыг оруулна уу",
            trigger: "blur",
          },
        ],
        menu_avg_time: [
          {
            required: true,
            message: "Бэлэн болох хугцацааг оруулна уу",
            trigger: "blur",
          },
        ],
        menu_categories: [
          {
            required: true,
            message: "Дотоод ангилал оруулна уу.",
            trigger: "change",
          },
        ],
      },
      limit: 10,
      from: 1,
      imgWidth: null,
      imgHeight: null,
      imageBase64: null,
      showUpload: true,
      menuCategoryList: [],
      productProperties: [],
      propertiesData: [],
      chosenOutlets: [],
      partnerCategories: [],
      dialogTableVisible: false,
      total_products: 0,
      total_active_products: 0,
      total_inactive_products: 0,
      productOutlets: [],
      choosedOutlet: "",
      current: null,
      selectCategory: null,
    };
  },
  methods: {
    setSelectCategory(category) {
      category.is_select = !category.is_select;
      this.selectCategory = category;
    },
    filterProduct(text) {
      const search = text.toLowerCase();
      const tempData = JSON.parse(JSON.stringify(this.backupData));
      if (search === "") {
        this.products = tempData;
      } else {
        this.products = tempData.filter((item) => {
          if (item?.name_mon.toLowerCase().indexOf(search) > -1) {
            return item;
          }
        });
      }
    },

    closeCopyProductDialog() {
      this.copyProductDialog = false;
    },

    clearData(type) {
      if (type === false) {
        this.menu.special_text = [];
        this.menu.special_text_eng = [];
      }
    },
    setSpecialProduct(data) {
      this.menu.special_text_eng = [];
      if (data.length > 0) {
        data.forEach((element) => {
          this.suggestMenu.forEach((secondElement) => {
            if (secondElement.name_mon === element) {
              this.menu.special_text_eng.push(secondElement.name_eng);
            }
          });
        });
      } else {
        this.menu.is_special = false;
      }
    },
    async getSuggestMenu() {
      await mAxios.get("product/suggestMenu").then(async (response) => {
        if (response.data.status === "success") {
          this.suggestMenu = response.data.data;
        } else {
          this.suggestMenu = [];
        }
      });
    },
    setTimeLimit(data) {
      this.menu.time_limit_text_eng = [];
      data.forEach((selectedElement) => {
        this.specialData.forEach((element) => {
          if (element.name_mon === selectedElement) {
            this.menu.time_limit_text_eng.push(element.name_eng);
          }
        });
      });
    },
    checkTimeLimit(type) {
      if (type === false) {
        this.menu.time_limit_text = [];
        this.menu.time_limit_text_eng = [];
      }
    },
    deleteItem(item) {
      this.menu.variants = this.menu.variants.filter((el) => el !== item);
    },
    setAllProduct(type) {
      this.isAllFood = !type;
      const tempData = [];
      if (this.isAllFood === true) {
        this.outletMenus.forEach((element) => {
          if (!tempData.includes(element)) {
            const temp = {
              pkey: element.pkey,
              name_mon: element.name_mon,
              name_eng: element.name_eng,
              outlet_name: element.outlet_name,
              outlet_id: this.currentOutlet._id,
              addon_price: element.addon_price,
              addon_limit: Object.prototype.hasOwnProperty.call(
                element,
                "addon_limit"
              )
                ? element.addon_limit
                : 0,
              menu_id: element.product_id,
            };
            tempData.push(temp);
          }
        });
        this.menu.addon_menus = JSON.parse(JSON.stringify(tempData));
      } else {
        this.menu.addon_menus = [];
      }
    },

    editVariantFrom(data) {
      this.openVariantForm(data);
    },
    deleteProduct(id) {
      mAxios
        .delete(`product/${id}`)
        .then((response) => {
          if (response.data.status === "success") {
            if (this.$root.chosenOutlets.length > 0) {
              this.setCurrentOutlet(this.$root.chosenOutlets[0]);
            }
            this.$message({
              message: "Амжилттай устгалаа",
              type: "success",
            });
            if (this.$route.query.outletId) {
              this.$router.push({
                name: "category",
                query: { outletId: this.$route.query.outletId },
              });
            }
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((e) => {
          this.$message({
            message: e.response.data.message,
            type: "error",
          });
        });
    },
    closeDeleteDialog() {
      this.isShowDeleteDialog = false;
    },
    setProduct(product) {
      if (product.is_checked) {
        this.selectedProducts.push(product);
      } else {
        this.selectedProducts = this.selectedProducts.filter(
          (el) => el.pkey !== product.pkey
        );
      }
      if (this.selectedProducts.length === 0) {
        this.copyProductDialog = false;
      }
    },
    copyProduct(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const body = {};
          const tempData = [];
          body.outletIds = this.selectSuppliers.suppliers;
          this.selectedProducts.forEach((el) => {
            tempData.push(el.pkey);
          });
          body.pkeys = tempData;
          this.copyLoading = true;
          mAxios
            .post("/product/copyProduct", body)
            .then((response) => {
              if (response.data.status === "success") {
                this.$message.success("Амжилттай хуулагдлаа");
                this.copyLoading = false;
                this.copyProductDialog = false;
                this.selectSuppliers.suppliers = [];
                this.selectedProducts = [];
                this.setCurrentOutlet(this.currentOutlet);
              } else {
                this.$message.error(response.data.message);
                this.copyLoading = false;
              }
            })
            .catch((e) => {
              this.$message({
                message: e.response.data.message,
                type: "error",
              });
              this.copyLoading = false;
              this.copyProductDialog = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getOutletData(outletId) {
      this.loading = true;
      const includeFields = [
        "_id",
        "full_name_mon",
        "full_name_eng",
        "service_list",
        "menu_categories",
        "outlet_name",
        "phone_list",
        "img_url",
      ];
      mAxios
        .post(`/outlet/${outletId}/get`, { include_fields: includeFields })
        .then((res) => {
          if (res.data.status === "success") {
            this.outletData = res.data.data;
            this.getMenuCategories();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((e) => {
          this.$message({
            message: e.response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getFile(file) {
      this.loadUpload = true;
      const image = new Image();
      image.src = URL.createObjectURL(file.raw);
      this.menu.img_url = URL.createObjectURL(file.raw);
      const promise = new Promise((resolve) => {
        image.onload = function () {
          resolve({ width: image.width, height: image.height });
        };
      });
      const result = await promise;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (
        !(
          file.raw.type === "image/png" ||
          file.raw.type === "image/jpeg" ||
          file.raw.type === "image/jpg"
        )
      ) {
        this.$message.error(
          "Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой."
        );
        this.menu.img_url = this.outletData.img_url;
        this.loadUpload = false;
      } else if (result.width !== 600 && result.height !== 400) {
        this.$message.error("Зурагны хэмжээ нь 600х400 байх ёстой!");
        this.menu.img_url = this.outletData.img_url;
        this.loadUpload = false;
      } else if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 1MB байх ёстой!");
        this.menu.img_url = this.outletData.img_url;
        this.loadUpload = false;
      } else {
        if (!this.menu.name_eng) {
          this.$message.warning(
            "Зурагны aнгли нэрийг заавал оруулах ёстой шүү!"
          );
          this.menu.img_url = this.outletData.img_url;
          this.loadUpload = false;
        }
        getBase64(file.raw).then((res) => {
          const payload = {};
          payload.base64 = res;
          mAxios.post("other/uploadImage", payload).then((res) => {
            this.menu.img_url = res.data.data;
            this.loadUpload = false;
          });
        });
      }
    },
    async setCurrentOutlet(outlet) {
      this.isSelectedProduct = false;
      this.selectProductValue = "";
      this.selectedProducts = [];
      this.selectSuppliers.suppliers = [];
      this.getOutletData(outlet._id);
      this.currentOutlet = outlet;
      this.getMenus(outlet._id);
      this.getProductProperties();
      this.getPartnerMenuCategories(outlet._id);
    },
    handleVariantDropdown(data) {
      if (data.command === "edit") {
        this.openVariantForm(data.data);
      }
      if (data.command === "delete") {
        this.removeVariant(data.data);
      }
    },
    openVariantForm(variant) {
      if (variant) {
        variant.action = "update";
        this.currentVariant = variant;
      } else {
        this.currentVariant = {
          variant_name_mon: "",
          variant_name_eng: "",
          is_required: false,
          options: [],
          action: "create",
        };
      }
      this.variantDialogVisible = true;
    },
    removeVariant(variant) {
      this.productForm.variants = this.productForm.variants.filter(
        (item) => item !== variant
      );
    },
    removeOption(variant, option) {
      variant.options = variant.options.filter((item) => option !== item);
    },
    addMenu(item) {
      this.menu = {
        name_mon: "",
        name_eng: "",
        desc_mon: "",
        desc_eng: "",
        menu_price: 0,
        menu_avg_time: 15,
        is_active: false,
        img_url: "",
        variants: [],
        is_new: true,
        addon_menus: [],
        is_addon: false,
        outlet_name: this.outletData.outlet_name,
        menu_category_mon: "",
        menu_category_eng: "",
        outlet_id: this.currentOutlet._id,
        user: this.currentOutlet.outlet_name,
        menu_categories: {
          service: item.service,
        },
        is_time_limit: false,
        time_limit_text: [],
        time_limit_text_eng: [],
      };
    },

    changeAvgTime(avgTime, item) {
      if (!this.$root.isAdmin) {
        if (
          item.service_list.includes("food") ||
          item.service_list.includes("cake") ||
          item.service_list.includes("drinks")
        ) {
          if (avgTime < 61) {
            this.menu.menu_avg_time = avgTime;
          } else {
            this.$message({
              type: "error",
              message:
                "Бүтээгдэхүүн бэлэн болох хугацаа 60 минутаас хэтрэхгүй байх ёстой",
            });
            if (avgTime > 60) this.menu.menu_avg_time = 60;
          }
        } else if (
          item.service_list.includes("flower delivery") ||
          item.service_list.includes("grocery") ||
          item.service_list.includes("delivery") ||
          item.service_list.includes("toktok mall")
        ) {
          if (avgTime < 120 && avgTime > 60) {
            this.menu.menu_avg_time = avgTime;
          } else {
            this.$message({
              type: "error",
              message:
                "Бүтээгдэхүүний бэлэн болох хугацаа 60-120 минутын хооронд байх ёстой",
            });
            if (avgTime > 120) {
              this.menu.menu_avg_time = 120;
            } else if (avgTime < 60) this.menu.menu_avg_time = 60;
          }
        } else this.menu.menu_avg_time = avgTime;
      }
    },

    async addCategory(categoryForm) {
      const payload = {
        menu_category_mon: categoryForm.menu_category_mon,
        menu_category_eng: categoryForm.menu_category_eng,
        outlet_id: this.currentOutlet._id,
      };
      await mAxios
        .post("outlet/addMenuCategoryOutlet", payload)
        .then(async (response) => {
          this.loadUpload = true;
          if (response.data.status === "success") {
            this.$notify({
              title: "Амжилттай хадгалагдлаа",
              message: response.data.data.message,
              type: "success",
              position: "bottom-left",
            });
            this.setCurrentOutlet(this.currentOutlet);
            // this.$router.go()
            this.loadUpload = false;
          } else {
            this.$notify({
              title: "Алдаа",
              message: response.data.message,
              type: "error",
              position: "bottom-left",
            });
          }
          this.menuLoading = false;
        });
      this.categoryDialogVisible = false;
    },
    closeCategoryDialog() {
      this.categoryDialogVisible = false;
    },
    closeVariantDialog() {
      this.variantDialogVisible = false;
      this.currentVariant = {
        variant_name_mon: "",
        variant_name_eng: "",
        is_required: false,
        options: [],
        action: "create",
      };
    },
    closeEditRequestDialog() {
      this.editRequestDialogVisible = false;
      this.editRequest = null;
      this.$forceUpdate();
    },
    addVariant(variantData) {
      const emptyTypes = [null, "", undefined];
      if (variantData.options.length <= 0) {
        this.$notify({
          message: "Сонголт нэмнэ үү!!!",
          type: "warning",
        });
      } else if (variantData.options.length >= 1) {
        if (
          variantData.options.find((el) =>
            emptyTypes.includes(el.option_name_eng)
          )
        ) {
          this.$notify({
            message: "Англи нэр бичээгүй байна!!!",
            type: "warning",
          });
          return false;
        } else if (
          variantData.options.find((el) =>
            emptyTypes.includes(el.option_name_mon)
          )
        ) {
          this.$notify({
            message: "Монгол нэр бичээгүй байна!!!",
            type: "warning",
          });
          return false;
        } else {
          variantData.options.map((el, index) => {
            if (
              emptyTypes.includes(el.option_name_eng) &&
              emptyTypes.includes(el.option_name_mon) &&
              [null, 0, undefined].includes(el.option_price)
            ) {
              variantData.options.splice(index, 1);
            }
          });
          variantData.label = variantData.variant_name_mon;
          if (variantData.action === "create") {
            if (Object.prototype.hasOwnProperty.call(this.menu, "variants")) {
              this.menu.is_variant = true;
              this.menu.variants.push(variantData);
              this.currentVariant = {
                variant_name_mon: "",
                variant_name_eng: "",
                is_required: false,
                options: [],
                action: "create",
              };
            } else {
              this.menu.variants = [];
              this.menu.is_variant = true;
              this.menu.variants.push(variantData);
            }
          }
          this.variantDialogVisible = false;
        }
      }
    },
    getMenu(id) {
      this.isAllFood = false;
      this.isSelectedProduct = true;
      this.loading = true;
      this.menu = null;
      mAxios
        .get(`product/getProductById/${id}`)
        .then((menuResponse) => {
          if (menuResponse.data.status === "success") {
            this.menu = menuResponse.data.data;
            this.menu._id = this.currentOutlet?._id;
            this.menu.user = this.outletData?.outlet_name;
            this.menu.product_id = id;
            this.propertiesData = this.menu.properties;
          }
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getMenus(id) {
      this.menuLoading = true;
      this.menu = null;
      mAxios.get(`product/${id}`).then((response) => {
        if (response.data.status === "success") {
          response.data.data.forEach((product) => {
            product.is_checked = false;
          });
          this.products = response.data.data;
          this.backupData = JSON.parse(JSON.stringify(response.data.data));
          this.generateProductCount();
        } else {
          this.$notify({
            title: "Алдаа",
            message: response.data.message,
            type: "error",
            position: "bottom-left",
          });
        }
        this.menuLoading = false;
      });
    },
    save(data) {
      if (data.time_limit_text.length === 0) {
        data.is_time_limit = false;
      }
      this.$refs.refproductForm.validate(async (valid) => {
        if (valid) {
          data.menu_price = parseInt(data.menu_price);
          if (data.is_new === true) {
            data._id = data.outlet_id ? data.outlet_id : data._id;
            await mAxios.post("product/addMenu", data).then((response) => {
              if (response.data.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: response.data.message,
                  type: "success",
                  position: "bottom-left",
                });
                if (this.$route.query.outletId) {
                  this.$router.push({
                    name: "category",
                    query: { outletId: this.$route.query.outletId },
                  });
                }
                this.$forceUpdate();
                this.setCurrentOutlet(data);
              } else {
                this.$notify({
                  title: "Алдаа",
                  message: response.data.message,
                  type: "error",
                  position: "bottom-left",
                });
              }
              this.loading = false;
            });
          } else {
            data.product_id = data.pkey;
            await mAxios.post("product/updateMenu", data).then((response) => {
              if (response.data.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: response.data.message,
                  type: "success",
                  position: "bottom-left",
                });
                if (this.$route.query.outletId) {
                  this.$router.push({
                    name: "category",
                    query: { outletId: this.$route.query.outletId },
                  });
                }
                this.$forceUpdate();
                this.setCurrentOutlet(data);
              } else {
                this.$notify({
                  title: "Алдаа",
                  message: response.data.message,
                  type: "error",
                  position: "bottom-left",
                });
              }
              this.loading = false;
            });
          }
        } else {
          this.$message.warning({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning",
          });
          return false;
        }
      });
    },
    async activeProduct(data) {
      data.user = this.outletData.outlet_name;
      data.product_id = data.pkey;
      data._id = data.outlet_id;
      await mAxios.post("product/updateMenu", data).then((response) => {
        if (response.data.status === "success") {
          this.$notify({
            title: "Амжилттай",
            message: data.is_active
              ? response.data.data.message
              : "Та борлуулалтаа алдаж байна.",
            type: data.is_active ? "success" : "warning",
            position: "bottom-left",
          });
          this.$forceUpdate();
        } else {
          this.$notify({
            title: "Алдаа",
            message: response.data.message,
            type: "error",
            position: "bottom-left",
          });
        }
        this.loading = false;
      });
    },
    generatePkey() {
      const u =
        Date.now().toString(16) + Math.random().toString(16) + "0".repeat(16);
      const guid = [
        u.substr(0, 8),
        u.substr(8, 4),
        "4000-8" + u.substr(13, 3),
        u.substr(16, 12),
      ].join("-");
      return guid;
    },
    showEditRequest(product) {
      this.editRequest = product;
      this.editRequestDialogVisible = true;
    },
    onChangeIsAddon(value) {
      if (value) {
        this.getMenusByOutlet();
      }
    },
    getMenusByOutlet() {
      const temp = {
        outlet_id: this.currentOutlet._id,
      };
      mAxios.post("getMenusByOutlet", temp).then((menuResponse) => {
        this.outletMenus = menuResponse.data.data;
      });
    },
    onChangeAddonMenuSelect(addOnMenuList) {
      const tempArr = [];
      addOnMenuList.forEach((el) => {
        const temp = {
          pkey: el.pkey,
          name_mon: el.name_mon,
          name_eng: el.name_eng,
          outlet_name: el.outlet_name,
          outlet_id: this.currentOutlet._id,
          addon_price: el.addon_price,
          addon_limit: Object.prototype.hasOwnProperty.call(el, "addon_limit")
            ? el.addon_limit
            : 0,
          menu_id: el.product_id,
        };
        tempArr.push(temp);
      });
      this.menu.addon_menus = JSON.parse(JSON.stringify(tempArr));
      this.$forceUpdate();
    },
    setPage(val) {
      this.from = val;
      this.getProductHistories();
    },
    getProductHistoriesId(id) {
      this.product_id = id;
      this.isShowHistoryListDialog = true;
      // this.getProductHistories()
    },
    closeHistoryListDialog() {
      this.isShowHistoryListDialog = false;
    },
    getMenuCategories() {
      mAxios
        .post("getMenuCategories", {
          service_list:
            this.outletData &&
            Object.prototype.hasOwnProperty.call(
              this.outletData,
              "service_list"
            )
              ? this.outletData.service_list
              : ["food"],
        })
        .then((categoriesResponse) => {
          if (categoriesResponse.data.status === "success") {
            this.menuCategoryList = categoriesResponse.data.data;
          }
        });
    },
    getPartnerMenuCategories(id) {
      mAxios
        .post("product/getPartnerMenuCategories", {
          outlet_id: id,
        })
        .then((categoriesResponse) => {
          if (categoriesResponse.data.status === "success") {
            categoriesResponse.data.data.forEach((category) => {
              category.is_select = false;
            });
            this.partnerCategories = categoriesResponse.data.data;
          }
        });
    },
    addMenuCatgory() {
      this.getMenuCategories();
      this.categoryDialogVisible = true;
    },
    getProductProperties() {
      mAxios
        .post("product/getProductPropertiesByService", {
          service_list:
            this.outletData &&
            Object.prototype.hasOwnProperty.call(
              this.outletData,
              "service_list"
            )
              ? this.outletData.service_list
              : ["food"],
        })
        .then((properties) => {
          this.productProperties = properties.data.data[0].properties;
        });
    },
    addProperties() {
      this.propertiesData.push({ name: "", value: "" });
    },
    deleteProperties(index) {
      this.propertiesData.splice(index, 1);
    },
    // getProductStat () {
    //   this.loading = true
    //   if (this.$root.chosenOutlets.length > 0) {
    //     this.$root.chosenOutlets.map(el => {
    //       this.outlets.push(el._id)
    //     })
    //   }
    //   this.outlets.push(this.$root.outlet._id)
    //   mAxios
    //     .post('product/getProductsStat', { outletIds: this.outlets })
    //     .then(response => {
    //       if (response.data.status === 'success') {
    //         if (response.data.data.length > 0) {
    //           this.productOutlets = response.data.data
    //           this.productOutlets.map(item => {
    //             this.total_products += item.total_products
    //             this.total_active_products += item.total_active_products
    //           })
    //           this.current = this.productOutlets[0]
    //           this.choosedOutlet = this.current.outlet_name
    //         }
    //       } else {
    //         this.$notify({
    //           title: 'Алдаа',
    //           message: response.data.message,
    //           type: 'error',
    //           position: 'bottom-left'
    //         })
    //       }
    //       this.loading = false
    //     })
    // },
    generateProductCount() {
      let inActiveProduct = 0;
      let activeProduct = 0;
      this.products.forEach((product) => {
        if (product.is_active) {
          activeProduct++;
        } else {
          inActiveProduct++;
        }
      });
      this.total_active_products = activeProduct;
      this.total_inactive_products = inActiveProduct;
    },
    getMenuTimeLimits() {
      mAxios.get("product/getMenuTimeLimits").then((response) => {
        if (response.data.status === "success") {
          this.specialData = response.data.data;
        } else {
          this.specialData = [];
        }
      });
    },
    chooseOutlet() {
      this.current = this.productOutlets.filter(
        (item) => item.outlet_name === this.choosedOutlet
      )[0];
    },
  },
};
</script>
<style>
.custom-button .el-button.el-button--default:focus,
.el-button.el-button--default:hover {
  border: none;
}
.custom-element-select .el-input__inner {
  font-size: 12px;
  color: #231f20;
  border: 2px solid #e6e6e6 !important;
  border-radius: 12px !important;
  min-height: 55px !important;
  max-height: 100% !important;
  background-color: #f8f8f8;
}
*:focus {
  outline: none;
}
.el__input {
  border-radius: 50px;
}
.Acitve {
  box-shadow: inset -11px 0 0px -7px #ffb500;
}
.productActiveClass {
  box-shadow: inset -11px 0 0px -7px black;
  background-color: #e6e6e6;
}
.diffHistory {
  /* background-color: #E4EBE4;  */
  color: rgb(135, 206, 135);
}
.fade-in {
  opacity: 0;
  animation: fade-in 0.5s ease-in forwards;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}
</style>
