<template>
  <div class="flex md:flex-row h-full md:hidden text-11 w-full gap-5 px-4 py-2.5 bg-baseWhite">
    <button class="px-15 py-17 bg-baseBackgroundGray rounded-xl font-semibold w-full" @click="back">Болих</button>
    <el-popconfirm
      title="Та устгахдаа итгэлтэй байна уу?"
      confirm-button-text="Тийм"
      cancel-button-text="Үгүй"
      @confirm="deleteProduct(menu?.product_id)"
      class="w-full"
    >
      <button slot="reference" class="custom-button px-15 py-17 bg-baseBackgroundGray text-baseRed rounded-xl font-semibold w-full border-none">Устгах</button>
    </el-popconfirm>
    <button class="px-15 py-17 bg-baseOrange rounded-xl font-semibold w-full"  @click="save(menu)">Хадгалах</button>
  </div>
</template>

<script>
export default {
  name: 'MobileFooter',
  props: {
    menu: {
      type: Object,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => null
    },
    deleteProduct: {
      type: Function,
      default: () => null
    }
  },
  created () {
    this.currentOutlet = this.$root.chosenOutlets.filter(outlet => outlet._id === this.$route.query.outletId)[0]
  },
  data () {
    return {
      currentOutlet: null
    }
  },
  methods: {
    back () {
      this.$router.push({
        name: 'category',
        query: {
          outletId: this.menu.outlet_id
        },
        params: {
          currentOutlet: this.currentOutlet
        }
      })
    }
  }
}
</script>

<style>
.custom-button .el-button.el-button--default:focus, .el-button.el-button--default:hover {
  border: none;
}
</style>
