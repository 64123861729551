<template>
  <el-card>
    <el-form
      v-if="data[type]"
    >
      <div class="panel-item">
        <el-row>
          <el-col :span="12" class="text-left">
            <el-form-item v-if="data[type]">
              <el-col :span="10">
                <div class="image-upload-container">
                  <div class="image-uploader invoice-image">
                    <div class="image-placeholder">
                      <div class="image-container">
                        <div class="overlay">
                          <el-upload
                            action=""
                            disabled
                            :show-file-list="false"
                            :auto-upload="false"
                          >
                            <img
                              v-if="data[type].img_url"
                              :src="data[type].img_url"
                              class="avatar"
                              style="width:100%"
                            />
                            <span
                              ><i
                                v-if="data[type].img_url"
                                slot="default"
                                class="el-icon-plus"
                                >Зураг солих</i
                              >
                              <i
                                v-else
                                class="el-icon-plus avatar-uploader-icon"
                                style="width:300px; height:200px;"
                                >Зураг оруулах</i
                              ></span
                            >
                          </el-upload>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div>
        <el-row :gutter="40">
          <el-col :span="40">
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
              <el-col :span="12" :class="checkDiff('is_active')">
                <el-form-item>
                  <el-checkbox v-model="data[type].is_active" disabled>
                    Бүтээгдэхүүн нээлттэй эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
                <el-col :span="12" :class="checkDiff('menu_price')">
                    <el-form-item label="Үнэ">
                    <el-input v-model="data[type].menu_price" disabled>
                        Үнэ
                    </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" :class="checkDiff('menu_avg_time')">
                  <el-form-item label="Бэлдэх хугацаа">
                    <el-input
                        v-model="data[type].menu_avg_time"
                        disabled
                    >
                        Бэлдэх хугацаа
                    </el-input>
                  </el-form-item>
                </el-col>
            </el-row>
          </div>
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
              <el-col :span="12" :class="checkDiff('name_mon')">
                <el-form-item label="Нэр(Монгол)">
                  <el-input
                    v-model="data[type].name_mon"
                    disabled
                    class="aa"
                  >
                    Нэр(Монгол)
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" :class="checkDiff('name_eng')">
                <el-form-item label="Нэр(Англи)">
                  <el-input
                    v-model="data[type].name_eng"
                    disabled
                    class="aa"
                  >
                    Нэр(Англи)
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
              <el-col :span="12" :class="checkDiff('menu_categories')">
                <el-form-item label="Дотоод ангилал">
                  <el-select v-model="data[type].menu_categories"
                   multiple
                   disabled
                   value-key="menu_category_mon"
                   class="dblock">
                    <el-option
                      v-for="(item, index) in $root.outlet.menu_categories"
                      :key="index"
                      :label="item.menu_category_mon"
                      :value="item"
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="panel-item">
            <el-row :gutter="20" class="mb30">
              <el-col :span="12" :class="checkDiff('desc_mon')">
                <el-form-item label="Тайлбар(Монгол)">
                  <el-input type="textarea" autosize
                    v-model="data[type].desc_mon"
                    disabled
                    class="aa"
                  >
                    Тайлбар(Монгол)
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" :class="checkDiff('desc_eng')">
                <el-form-item label="Тайлбар(Англи)">
                  <el-input type="textarea" autosize
                    v-model="data[type].desc_eng"
                    disabled
                    class="aa"
                  >
                    Тайлбар(Англи)
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: 'DetailComponent',
  props: {
    data: {
      type: Object,
      default: () => null
    },
    type: {
      type: String,
      default: () => null
    }
  },
  methods: {
    checkDiff (field) {
      if (JSON.stringify(this.data.old[field]) === JSON.stringify(this.data.new[field])) {
        return ''
      } else {
        return 'diffHistory'
      }
    }
  }
}
</script>

<style>
.aa{
  color: green;
}
.diffHistory {
  /* background-color: #E4EBE4;  */
  border-style: dotted;
}
</style>
