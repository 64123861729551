<template>
  <div>
    <el-dialog
      :title="'Харилцагчийн түүх'"
      :visible="isShow"
      width="60%"
      style="align-items: center; height: 100%"
      @close="mCloseDialog()"
      @open="mOpenDialog()"
    >
      <el-table :data="historyList">
        <el-table-column
          type="index"
          label="№"
          width="50"
        ></el-table-column>
        <el-table-column prop="created_at" label="Огноо"></el-table-column>
        <el-table-column prop="action" label="Үйлдэл"></el-table-column>
        <el-table-column
          prop="desc"
          label="Тайлбар"
        ></el-table-column>
        <el-table-column prop="user" label="Хэрэглэгч"></el-table-column>
        <el-table-column label="Дэлгэрэнгүй">
          <template slot-scope="prop">
            <el-button
              type="primary"
              size="mini"
              @click="getHistoryDetail(prop.row)"
              >Дэлгэрэнгүй</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="text-right mt10"
        background
        layout="total, prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="getHistoryList"
      >
      </el-pagination>
    </el-dialog>
    <DetailDialog
      :isShow="isDetailShow"
      :historyId="historyId"
      :closeFunction="mCloseDetailDialog"
    />
  </div>
</template>
<script>
import mAxios from '../../../../../helper/axiosInstance'
import DetailDialog from './detail.vue'
export default {
  name: 'ProductHistoryList',
  components: {
    DetailDialog
  },
  props: {
    isShow: {
      type: Boolean,
      default: () => false
    },
    outletId: {
      type: String,
      default: () => null
    },
    closeFunction: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      historyList: [],
      total: 0,
      page: 1,
      pageSize: 10,
      isDetailShow: false,
      historyId: null
    }
  },
  methods: {
    mCloseDialog () {
      this.isDetailShow = false
      this.closeFunction()
    },
    mOpenDialog () {
      this.getHistoryList(1)
    },
    // getProductHistories () {
    //   this.productDetail = []
    //   const payload = {}
    //   payload.product_id = this.product_id
    //   payload.from = this.from
    //   mAxios.post('getProductHistories', payload).then((response) => {
    //     if (response.data.status === 'success') {
    //       this.productDetail = response.data.data
    //       this.total = response.data.count
    //       this.isDialogShow = true
    //     } else {
    //       this.$notify({
    //         title: 'Алдаа',
    //         message: response.data.message,
    //         type: 'error',
    //         position: 'bottom-left'
    //       })
    //     }
    //     this.loading = false
    //   })
    // },
    getHistoryList (page) {
      const payload = {
        product_id: this.outletId,
        from: page
      }
      mAxios.post('product/getProductHistories', payload).then(res => {
        if (res.data.status === 'success') {
          this.historyList = res.data.data.data
          this.page = res.data.data.current_page
          this.total = res.data.data.count
          this.pageSize = res.data.data.page_size
        }
      })
    },
    mCloseDetailDialog () {
      this.isDetailShow = false
    },
    getHistoryDetail (data) {
      this.historyId = data._id
      this.isDetailShow = true
    },
    setPage () {
    }
  },
  mounted () {
  }
}
</script>
