<template>
	<el-dialog
		:visible.sync="dialogVisible"
    :custom-class="dialogCustomClass"
    width="750px"
		:close-on-click-modal="false"
		:before-close="closeVariantDialog">
      <div class="flex flex-col justify-center items-center gap-4">
        <span class="text-base font-bold">Бүтээгдэхүүнд сонголт оруулах</span>
        <div class="flex flex-row gap-2.5 px-2.5 bg-baseBackgroundGray rounded-lg h-33 items-center justify-center">
          <el-switch v-model="variantForm.is_required"></el-switch>
          <span class="text-xs font-semibold">Заавал сонгох эсэх</span>
        </div>
      </div>
      <div class="flex flex-row w-full gap-20 mt-5">
        <div class="flex md:flex-row flex-col gap-20">
          <div class="flex flex-col gap-5 w-343">
            <span class="font-semibold text-xs">Сонголтын төрөл</span>
            <input v-model="variantForm.variant_name_mon" type="text" class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-sm bg-baseBackgroundGray" placeholder="Өнгө ..." />
          </div>
          <div class="flex flex-col gap-5 w-343">
            <span class="font-semibold text-xs">Сонголтын төрөл ( Англи )</span>
            <input v-model="variantForm.variant_name_eng" type="text" class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-sm bg-baseBackgroundGray" placeholder="Color ..." />
          </div>
        </div>
      </div>
      <div class="w-full border-1 border-dashed border-baseGray mt-4"></div>
      <div class="w-full" v-if="variantForm.options.length > 0">
        <div class="flex md:flex-row flex-col gap-2.5 w-full mt-4" v-for="(option, optionIndex) in variantForm.options" :key="optionIndex">
          <div class="flex flex-col gap-5 w-full">
            <span class="font-semibold text-xs" v-if="optionIndex === 0">Сонголтын нэр</span>
            <input v-model="option.option_name_mon" type="text" class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-sm bg-baseBackgroundGray" placeholder="Хар ..." />
          </div>
          <div class="flex flex-col gap-5 w-full">
            <span class="font-semibold text-xs" v-if="optionIndex === 0">Сонголтын нэр ( Англи )</span>
            <input v-model="option.option_name_eng" type="text" class="w-full border-2 rounded-xl border-baseGray py-15 px-17 text-sm bg-baseBackgroundGray" placeholder="Black ..." />
          </div>
          <div class="flex flex-col gap-5 w-full">
            <span class="font-semibold text-xs" v-if="optionIndex === 0">Үндсэн үнэ дээр нэмэх үнэ</span>
            <div class="flex flex-row gap-2.5">
              <div class="flex flex-row">
                <input type="text" v-model="option.option_price" class="w-full border-2 rounded-l-xl border-baseGray py-15 px-17 text-sm bg-baseBackgroundGray" placeholder="Үнэ оруулах ..." />
                <span class="flex flex-row justify-center items-center bg-baseGray w-14 rounded-r-xl">₮</span>
              </div>
              <button class="flex flex-row justify-center items-center px-15 md:h-full rounded-xl bg-baseBackgroundGray" @click="removeOption(option)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M4 7H20M10 11V17M14 11V17M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7" stroke="#F24E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-full mt-4">
        <span class="flex flex-row w-full h-full items-center justify-center opacity-50">Өгөгдөл байхгүй байна...</span>
      </div>
      <button class="flex flex-row items-center justify-center bg-baseBackgroundGray rounded-xl w-full py-15 gap-5 mt-4" @click="addOption">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path d="M11.5 13.9658H5.5V11.9658H11.5V5.96582H13.5V11.9658H19.5V13.9658H13.5V19.9658H11.5V13.9658Z" fill="#3A3A3C"/>
        </svg>
        <span class="text-sm font-semibold">Сонголт нэмэх</span>
      </button>
      <div class="flex flex-row w-full justify-center items-center">
        <div class="flex flex-row mt-7 w-343 gap-5 text-xs font-semibold text-baseBlack">
          <button class="w-full py-17 px-15 bg-baseBackgroundGray rounded-xl" @click="closeVariantDialog">Болих</button>
          <button class="w-full py-17 px-15 bg-baseOrange rounded-xl" @click="addVariant(variantForm)">{{variantForm.action === 'create' ? 'Нэмэх' : 'Засах'}}</button>
        </div>
      </div>
	</el-dialog>
</template>
<script>
export default {
  name: 'AddVariantDialog',
  props: [
    'dialogVisible',
    'closeVariantDialog',
    'addVariant',
    'variantForm'
  ],
  data () {
    return {
      dialogCustomClass: 'custom-dialog-class',
      rules: {
        name_mon: [
          { required: true, message: 'Нэрийг оруулна уу', trigger: ['blur', 'change'] }
        ],
        name_eng: [
          { required: true, message: 'Англи Нэрийг оруулна уу', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    removeOption (item) {
      this.variantForm.options = this.variantForm.options.filter(option => option !== item)
    },
    addOption () {
      this.variantForm.options.push({
        option_name_mon: '',
        option_name_eng: '',
        option_price: 0
      })
    }
  }
}
</script>
<style>
*:focus {
    outline: none;
}
.custom-dialog-class {
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
</style>
